import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import MainMenu1 from '../components/MainMenu1'
import MyHeader from '../components/MyHeader'
import UserContext from '../context/MyContent';
import { useNavigate } from 'react-router-dom';
import MyLizingScreen from './MyLizingScreen';
import MySavingScreen from './MySavingScreen';
import MyInfoScreen from './MyInfoScreen';
import ChangepassScreen from './ChangepassScreen';
import MySavingHuulgaScreen from './MySavingHuulgaScreen';
import MyLizingGraphic from './MyLizingGraphic';
import MyLizingHuulga from './MyLizingHuulga';
import WebContentScreen from './WebContentScreen';
import WebContentEditScreen from './WebContentEditScreen';

export default function Dashboard() {
    const context = useContext(UserContext);
    const navigate = useNavigate();
    let params = useParams();
    useEffect(() => {
        if (context.token === undefined || context.token === '') {
            navigate('/home');
        }

    }, [])
    return (
        <div class="bg3">
            <MyHeader />
            <div class="container">
                <div class="row">
                    <div class="col cp20 text-center mycolor hidephone">
                        <h2>Dashboard</h2>
                    </div>
                    <div class="col">
                        <MainMenu1 />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 cp20">
                        {
                            (() => {
                                switch (params.id) {
                                    case 'saving': return <MySavingScreen />; break;
                                    case 'savinghuulga': return <MySavingHuulgaScreen />; break;
                                    case 'lizing': return <MyLizingScreen />; break;
                                    case 'info': return <MyInfoScreen />; break;
                                    case 'changepass': return <ChangepassScreen />; break;
                                    case 'graphic': return <MyLizingGraphic />; break;
                                    case 'lizinghuulga': return <MyLizingHuulga />; break;
                                    case 'content': return <WebContentScreen />; break;
                                    case 'contentedit': return <WebContentEditScreen />; break;
                                    default: return <MyInfoScreen />; break;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}
