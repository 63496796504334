import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

export default function RightIcons() {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    return (
        <>
            <NavLink className="mt-5" to="/calc">
                <img class="mt-5" width={45} src="/right1.png" alt="Тооцоолуур" onMouseOver={() => { /*setShow1(true)*/ }} />
                {show1 ?
                    <div class="rlink" onMouseLeave={() => { /*setShow1(false)*/ }}>
                        <table>
                            <tr>
                                <td align='right'><img width={45} src="/right1.png" alt="" /></td>
                                <td align='left'><p style={{ backgroundColor: '#FFF' }}>Тооцоолуур</p></td>
                            </tr>
                        </table>
                    </div>
                    :
                    null
                }
            </NavLink><br />
            <a class="mt-5" href="https://www.youtube.com/watch?v=95xus4xJnTA" target="_blank" rel="noreferrer" onMouseOver={() => { /*setShow2(true)*/ }}>
                <img class="mt-5" width={45} src="/right2.png" alt="youtube" />
                {show2 ?
                    <div class="rlink" onMouseLeave={() => { /*setShow2(false)*/ }}>
                        <table>
                            <tr>
                                <td align='right'><img width={45} src="/right2.png" alt="" /></td>
                                <td align='left'><p style={{ backgroundColor: '#FFF' }}>Youtube</p></td>
                            </tr>
                        </table>
                    </div>
                    :
                    null
                }
            </a><br />
            <NavLink className="mt-5" to="/home/contact">
                <img class="mt-5" width={45} src="/right3.png" alt="..." />
            </NavLink><br />
            <a class="mt-5" href="http://m.me/evolution.holding" target="_blank" rel="noreferrer">
                <img class="mt-5" width={45} src="/right4.png" alt="..." />
            </a>
        </>
    )
}
