import React, { useState } from 'react';
const UserContext = React.createContext();

export const API_URL = 'https://apiv1.cscc.mn/erpapi1/'; // https://apiv1.cscc.mn/erpapi1/  http://192.168.1.4:4004/erpapi/ ;

export const UserStore = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    const [userid, setUserid] = useState('');
    const [fname, setFname] = useState('');
    const [uname, setUname] = useState('');
    const [phone, setPhone] = useState('');
    const [registr, setRegistr] = useState('');
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState(['УХ99999999', 'ШУ88061437'])
    const [hcode, setHcode] = useState('');
    const [htype, setHtype] = useState('');
    return (
        <UserContext.Provider
            value={{
                isLoggedIn, setIsLoggedIn, token, setToken, userid, setUserid, fname, setFname, uname, setUname, phone, setPhone, loading, setLoading, registr, setRegistr, info, setInfo, admins, hcode, setHcode, htype, setHtype
            }}>
            {props.children}
        </UserContext.Provider>
    )
}

export function currencyFormat2(num) {
    if (num)
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    else
        return 0
}

export function currencyFormat(num) {
    if (num)
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    else
        return 0
}

export default UserContext;