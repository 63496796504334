import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

function MainMenu() {
    const [subpro, setSubpro] = useState(false);
    const [subabout, setSubabout] = useState(false);
    const [subnews, setSubnews] = useState(false);

    return (
        <>
            <div class="mt-1" style={{ flexDirection: 'row' }}>
                <div class="showphone">
                    <table width="100%" align="center">
                        <tr>
                            <td width="25%" align="center">
                                <NavLink className="mt-5" to="/calc">
                                    <img class="mt-5" width={45} src="/right1.png" alt="Тооцоолуур" />
                                </NavLink>
                            </td>
                            <td width="25%" align="center">
                                <a class="mt-5" href="https://www.youtube.com/watch?v=95xus4xJnTA" target="_blank" rel="noreferrer">
                                    <img class="mt-5" width={45} src="/right2.png" alt="youtube" />
                                </a>
                            </td>
                            <td width="25%" align="center">
                                <NavLink className="mt-5" to="/home/contact">
                                    <img class="mt-5" width={45} src="/right3.png" alt="..." />
                                </NavLink>
                            </td>
                            <td width="25%" align="center">
                                <a class="mt-5" href="http://m.me/evolution.holding" target="_blank" rel="noreferrer">
                                    <img class="mt-5" width={45} src="/right4.png" alt="..." />
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <table align="right" class="mt-5">
                    <tr>
                        <td width="150" class="mymenucol">
                            <NavLink to="/" className="d-flex mydefcolor align-items-center justify-content-center text-center full" style={{ height: 40 }}>ЭХЛЭЛ</NavLink>
                        </td>
                        <td width="150" class="mymenucol">
                            <NavLink className="d-flex mydefcolor align-items-center justify-content-center text-center full" style={{ height: 40 }} to="/about/6" onMouseMove={() => { setSubabout(true) }} onMouseLeave={() => { setSubabout(false) }}>БИДНИЙ ТУХАЙ</NavLink>
                            <div class="submenucontainer" style={{ visibility: subabout ? 'visible' : 'hidden' }} onMouseMove={() => { setSubabout(true) }} onMouseLeave={() => { setSubabout(false) }}>
                                <ul>
                                    <li><NavLink className="submenuitem" to="/about/6" onClick={() => { setSubabout(false) }}>БИДНИЙ ТУХАЙ</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/about/7" onClick={() => { setSubabout(false) }}>БИД ХЭРХЭН АЖИЛЛАДАГ ВЭ?</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/about/8" onClick={() => { setSubabout(false) }}>ХАЯГ БАЙРШИЛ</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/about/9" onClick={() => { setSubabout(false) }}>НИЙГМИЙН ХАРИУЦЛАГА</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/about/10" onClick={() => { setSubabout(false) }}>АЖЛЫН БАЙР</NavLink></li>
                                </ul>
                            </div>
                        </td>
                        <td width="150" class="mymenucol">
                            <NavLink className="d-flex mydefcolor align-items-center justify-content-center text-center full" style={{ height: 40 }} to="/products" onMouseMove={() => { setSubpro(true) }} onMouseLeave={() => { setSubpro(false) }}>БҮТЭЭГДЭХҮҮН ҮЙЛЧИЛГЭЭ</NavLink>
                            <div class="submenucontainer" style={{ visibility: subpro ? 'visible' : 'hidden' }} onMouseMove={() => { setSubpro(true) }} onMouseLeave={() => { setSubpro(false) }}>
                                <ul>
                                    <li><NavLink className="submenuitem" to="/products" onClick={() => { setSubpro(false) }}>ЗЭЭЛ</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/saving" onClick={() => { setSubpro(false) }}>ХӨРӨНГӨ ОРУУЛАЛТ</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/shop" onClick={() => { setSubpro(false) }}>АВТО МАШИН ХУДАЛДАА</NavLink></li>
                                </ul>
                            </div>
                        </td>
                        <td width="150" class="mymenucol">
                            <NavLink className="d-flex mydefcolor align-items-center justify-content-center text-center full" style={{ height: 40 }} to="/news" onMouseMove={() => { setSubnews(true) }} onMouseLeave={() => { setSubnews(false) }}>МЭДЭЭ МЭДЭЭЛЭЛ</NavLink>
                            <div class="submenucontainer" style={{ visibility: subnews ? 'visible' : 'hidden' }} onMouseMove={() => { setSubnews(true) }} onMouseLeave={() => { setSubnews(false) }}>
                                <ul>
                                    <li><NavLink className="submenuitem" to="/news1/" onClick={() => { setSubnews(false) }}>КОМПАНИЙН МЭДЭЭ</NavLink></li>
                                    <li><NavLink className="submenuitem" to="/news2/" onClick={() => { setSubnews(false) }}>ОЛОН НИЙТИЙН МЭДЭЭ</NavLink></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default MainMenu
