import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import UserContext, { API_URL } from '../context/MyContent';

export default function MyLizingScreen() {
    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [slist, setSlist] = useState([]);
    const [haahdun, setHaahdun] = useState(0);
    const [gereecode, setGereecode] = useState('');
    const context = useContext(UserContext)
    const navigate = useNavigate();

    const showinfo = (gereecode) => {
        setGereecode(gereecode);
        setList1(slist.filter(slist => { return (slist.gereecode.includes(gereecode)) }));
        context.setLoading(true);
        axios.post(API_URL + 'web/lizinghaah/', {
            token: context.token,
            gereecode: gereecode
        })
            .then(res => {
                setHaahdun(res.data.haahdun);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }

    const showhuulga = (type) => {
        if (gereecode === '') {
            alert('Хуулга харах гэрээгээ сонгоно уу');
            return
        }
        context.setHtype(type);
        context.setHcode(gereecode);
        if (type === 'huulga') {
            navigate("/dashboard/lizinghuulga");
        }
        else {
            navigate("/dashboard/graphic");
        }
    }

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/myzeel/', {
            token: context.token
        })
            .then(res => {
                setList(res.data);
                setList1(res.data);
                setSlist(res.data);
                if (res.data.length > 0) {
                    setGereecode(list[0].gereecode);
                }
                axios.post(API_URL + 'web/lizinghaah/', {
                    token: context.token,
                    gereecode: list[0].gereecode
                })
                    .then(res => {
                        setHaahdun(res.data.haahdun);
                        context.setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        context.setLoading(false);
                    })
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [])

    return (
        <div class="mysaving">
            <table>
                <tr>
                    {
                        list.map(el => (
                            <td key={el.gereecode}>
                                <div class={gereecode === el.gereecode ? "stabactive" : "stab"}>
                                    <NavLink to="/dashboard/lizing" style={gereecode === el.gereecode ? { color: 'white' } : { color: '#234f41' }} onClick={(e) => { e.preventDefault(); showinfo(el.gereecode) }} className="slink">
                                        {el.gereecode}
                                    </NavLink>
                                </div>
                            </td>
                        ))
                    }
                </tr>
            </table>
            <table width="100%">
                <tr>
                    <td>
                        <div class="mysavinginfo">
                            <table width="100%">
                                <tr>
                                    <td>ГЭРЭЭНИЙ ТӨРӨЛ</td>
                                    <td>{list1[0] && list1[0].pname}</td>
                                </tr>
                                <tr>
                                    <td>ГЭРЭЭНИЙ КОД</td>
                                    <td>{list1[0] && list1[0].gereecode}</td>
                                </tr>
                                <tr>
                                    <td>АНХ АВСАН ЗЭЭЛИЙН ДҮН</td>
                                    <td>{list1[0] && list1[0].gereedun.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ҮНДСЭН ЗЭЭЛИЙН ҮЛДЭГДЭЛ</td>
                                    <td>{list1[0] && list1[0].gereeuld.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ХҮҮ / САРААР /</td>
                                    <td>{list1[0] && list1[0].huu}</td>
                                </tr>
                                <tr>
                                    <td>БОДОГДСОН ХҮҮ</td>
                                    <td>{list1[0] && list1[0].bodsonhuu1}</td>
                                </tr>
                                <tr>
                                    <td>ЗОГСООЛЫН ТӨЛБӨР</td>
                                    <td>{list1[0] && list1[0].nemeltdun}</td>
                                </tr>
                                <tr>
                                    <td>ТӨЛӨЛТ ХИЙХ ОГНОО</td>
                                    <td>{list1[0] && list1[0].nextdate}</td>
                                </tr>
                                <tr>
                                    <td>ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН ДҮН</td>
                                    <td>{list1[0] && list1[0].tatvardun}</td>
                                </tr>
                                <tr>
                                    <td>БАРЬЦААНЫ ТӨРӨЛ</td>
                                    <td>{list1[0] && list1[0].baritsaatype}</td>
                                </tr>
                                <tr>
                                    <td>БАРЬЦАА ХӨРӨНГӨ</td>
                                    <td>{list1[0] && list1[0].baritsaa} {list1[0] && list1[0].dugaar} {list1[0] && list1[0].mark}</td>
                                </tr>
                                <tr>
                                    <td>УРГИЙН ОВОГ</td>
                                    <td>{list1[0] && list1[0].ovog}</td>
                                </tr>
                                <tr>
                                    <td>ЭЦГИЙН НЭР</td>
                                    <td>{list1[0] && list1[0].fname}</td>
                                </tr>
                                <tr>
                                    <td>НЭР</td>
                                    <td>{list1[0] && list1[0].uname}</td>
                                </tr>
                                <tr>
                                    <td>РЕГИСТРИЙН ДУГААР</td>
                                    <td>{list1[0] && list1[0].registr}</td>
                                </tr>
                                <tr>
                                    <td>БАНКНЫ НЭР</td>
                                    <td>{list1[0] && list1[0].bankname}</td>
                                </tr>
                                <tr>
                                    <td>ДАНСНЫ ДУГААР</td>
                                    <td>{list1[0] && list1[0].dansname}</td>
                                </tr>
                                <tr>
                                    <td>ИРГЭНИЙ ҮНЭМЛЭХНИЙ ХАЯГ</td>
                                    <td>{list1[0] && list1[0].addr}</td>
                                </tr>
                                <tr>
                                    <td>УТАСНЫ ДУГААР</td>
                                    <td>{list1[0] && list1[0].phone}</td>
                                </tr>
                            </table>
                        </div>
                        <table width="100%" class="hidephone" style={{ marginTop: 20 }}>
                            <tr>
                                <td>
                                    <input type="button" class="savingbtn" value="Эргэн төлөлтийн хуулга" onClick={() => { showhuulga('huulga'); }} />
                                </td>
                                <td align='right'>
                                    <input type="button" class="savingbtn" value="Эргэн төлөлтийн график" onClick={() => { showhuulga('graphic'); }} />
                                </td>
                            </tr>
                        </table>
                        <table width="100%" class="showphone" style={{ marginTop: 20 }}>
                            <tr>
                                <td align='center'>
                                    <input type="button" class="savingbtn" value="Эргэн төлөлтийн хуулга" onClick={() => { showhuulga('huulga'); }} />
                                </td>
                            </tr>
                            <tr>
                                <td align='center'>
                                    <input type="button" class="savingbtn" value="Эргэн төлөлтийн график" onClick={() => { showhuulga('graphic'); }} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="hidephone" style={{ textAlign: 'center', paddingTop: 60 }}>
                        <h2>ЗЭЭЛ ХААХ </h2>
                        <h3>{haahdun.toLocaleString()}</h3>
                        <p style={{ color: 'black', fontWeight: 'bold', marginTop: 50 }}>ЗЭЭЛИЙН ТӨЛӨЛТ ХИЙХ ЗААВАР:</p>
                    </td>
                </tr>
            </table>
        </div>
    )
}
