import axios from 'axios';
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import UserContext, { API_URL, currencyFormat } from '../context/MyContent';

function ShopScreen() {
    const [list, setList] = useState([]);
    const [listall, setListall] = useState([]);
    const [motor, setMotor] = useState([]);
    const [motorid, setMotorid] = useState(0);
    const [hrop, setHrop] = useState([]);
    const [hropid, setHropid] = useState(0)
    const [most, setMost] = useState([]);
    const [mostid, setMostid] = useState(0);
    const [color, setColor] = useState([]);
    const [colorid, setColorid] = useState(0);
    const [carname, setCarname] = useState([]);
    const [carid, setCarid] = useState('');
    const [markname, setMarkname] = useState([]);
    const [markid, setMarkid] = useState('');
    const [pyear, setPyear] = useState(0);
    const [iyear, setIyear] = useState(0);
    const [cartype, setCartype] = useState(['Суудлын тэрэг', 'Жийп', 'Гэр бүлийн']);
    const [cartypeid, setCartypeid] = useState('');
    const [hurd, setHurd] = useState(['Зөв', 'Буруу']);
    const [hurdid, setHurdid] = useState('');
    const years = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000];
    const context = useContext(UserContext)
    const navigate = useNavigate();

    let params = useParams();

    const getdata = async () => {
        context.setLoading(true);
        const r1 = await axios.get(API_URL + 'web/car/markname/');
        setMarkname(r1.data);
        const r2 = await axios.get(API_URL + 'web/car/carname/');
        setCarname(r2.data);
        const r3 = await axios.get(API_URL + 'web/car/motor/');
        setMotor(r3.data);
        const r4 = await axios.get(API_URL + 'web/car/hrop/');
        setHrop(r4.data);
        const r5 = await axios.get(API_URL + 'web/car/most/');
        setMost(r5.data);
        const r6 = await axios.get(API_URL + 'web/car/color/');
        setColor(r6.data);
        const r7 = await axios.post(API_URL + 'web/carlist/', {
            cartype: cartypeid,
            carname: carid,
            markname: markid,
            pyear: pyear,
            iyear: iyear,
            motorid: motorid,
            hropid: hropid,
            hurd: hurdid,
            mostid: mostid,
            colorid: colorid
        });
        setList(r7.data);
        setListall(r7.data);
        context.setLoading(false);
    }

    const filter = async () => {
        const r7 = await axios.post(API_URL + 'web/carlist/', {
            cartype: cartypeid,
            carname: carid,
            markname: markid,
            pyear: pyear,
            iyear: iyear,
            motorid: motorid,
            hropid: hropid,
            hurd: hurdid,
            mostid: mostid,
            colorid: colorid
        });
        setList(r7.data);
    }

    useEffect(() => {
        filter();
    }, [cartypeid, carid, markid, pyear, iyear, motorid, hropid, hurdid, mostid, colorid])

    useLayoutEffect(() => {
        getdata();
    }, [])

    return (
        <div class="shopcontainer">
            <table width="100%">
                <tr>
                    <td class="calcp1" width="20%" align='center'>
                        <select class="custom-select form-control myselectwidth" value={carid} onChange={(e) => { setCarid(e.target.value); }}>
                            <option value=''>Үйлдвэрлэгч</option>
                            {
                                carname.map(el => (
                                    <option key={el.carname} value={el.carname}>{el.carname}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" width="20%" align='center'>
                        <select class="custom-select form-control myselectwidth" value={pyear} onChange={(e) => { setPyear(e.target.value); }}>
                            <option value={0}>Үйлд.Он</option>
                            {
                                years.map(el => (
                                    <option key={el} value={el}>{el}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" width="20%" align='center'>
                        <select class="custom-select form-control myselectwidth" value={motorid} onChange={(e) => { setMotorid(e.target.value); }}>
                            <option value={0}>Мотор</option>
                            {
                                motor.map(el => (
                                    <option key={el.motorid} value={el.motorid}>{el.motorname}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" width="20%" align='center'>
                        <select class="custom-select form-control myselectwidth" value={hurdid} onChange={(e) => { setHurdid(e.target.value); }}>
                            <option value={''}>Хүрд</option>
                            {
                                hurd.map(el => (
                                    <option key={el} value={el}>{el}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" width="20%" align='center'>
                        <select class="custom-select form-control myselectwidth" value={colorid} onChange={(e) => { setColorid(e.target.value); }}>
                            <option value={0}>Өнгө</option>
                            {
                                color.map(el => (
                                    <option key={el.colorid} value={el.colorid}>{el.colorname}</option>
                                ))
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="calcp1" align='center'>
                        <select class="custom-select form-control myselectwidth" value={markid} onChange={(e) => { setMarkid(e.target.value); }}>
                            <option value=''>Загвар</option>
                            {
                                markname.map(el => (
                                    <option key={el.markname} value={el.markname}>{el.markname}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" align='center'>
                        <select class="custom-select form-control myselectwidth" value={iyear} onChange={(e) => { setIyear(e.target.value); }}>
                            <option value={0}>Орж ирсэн</option>
                            {
                                years.map(el => (
                                    <option key={el} value={el}>{el}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" align='center'>
                        <select class="custom-select form-control myselectwidth" value={hropid} onChange={(e) => { setHropid(e.target.value); }}>
                            <option value={0}>Хурдны хайрцаг</option>
                            {
                                hrop.map(el => (
                                    <option key={el.hropid} value={el.hropid}>{el.hropname}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" align='center'>
                        <select class="custom-select form-control myselectwidth" value={mostid} onChange={(e) => { setMostid(e.target.value); }}>
                            <option value={0}>Хөтлөгч</option>
                            {
                                most.map(el => (
                                    <option key={el.mostid} value={el.mostid}>{el.mostname}</option>
                                ))
                            }
                        </select>
                    </td>
                    <td class="calcp1" align='center'>
                        <select class="custom-select form-control myselectwidth" value={cartypeid} onChange={(e) => { setCartypeid(e.target.value); }}>
                            <option value={''}>Ангилал</option>
                            {
                                cartype.map(el => (
                                    <option key={el} value={el}>{el}</option>
                                ))
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        <div class="carboxcontainer">
                            {
                                list.map(el => (
                                    <div key={el.id} class="carbox">
                                        <table width="100%">
                                            <tr>
                                                <td width="50%">
                                                    <a href='/shopinfo' onClick={(e) => {
                                                        e.preventDefault(); navigate("/shopinfo/" + el.id);
                                                    }}>
                                                        <img src={el.img1} width="100%" />
                                                    </a>
                                                </td>
                                                <td valign='top' class="cinfo">
                                                    <b>{el.carname + ' ' + el.markname}</b><br />
                                                    <b>Он : {el.pyear} / {el.iyear}</b><br />
                                                    <b class="hidephone">Ангилал :</b> <b>{el.cartype}</b><br />
                                                    <b class="hidephone">Мотор : {el.motorname}</b><br class="hidephone" />
                                                    <b class="hidephone">Хүрд : {el.hurd}</b><br class="hidephone" />
                                                    <b class="hidephone">Өнгө : {el.colorname}</b><br class="hidephone" />
                                                    <b>Үнэ : {currencyFormat(el.une)}₮</b><br />
                                                    <b>Утас : {el.phone}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                ))
                            }
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}

export default ShopScreen
