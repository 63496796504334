import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import UserContext, { API_URL } from '../context/MyContent';

export default function WebContentScreen() {
    const [list, setList] = useState([]);
    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/contentlist/', {
            token: context.token,
        })
            .then(res => {
                setList(res.data);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [])

    return (
        <table width="100%">
            <thead>
                <tr>
                    <td class="tblhead">№</td>
                    <td class="tblhead">Гарчиг</td>
                    <td class="tblhead">Модуль</td>
                    <td class="tblhead">Засах</td>
                </tr>
            </thead>
            {
                list.map(el => {
                    return (
                        <tr key={el.id}>
                            <td class="tblrow">{el.id}</td>
                            <td class="tblrow">{el.newstitle}</td>
                            <td class="tblrow">{el.menuid}</td>
                            <td class="tblrow">
                                <NavLink to={'/dashboard/contentedit/' + el.id} >Засах</NavLink>
                            </td>
                        </tr>
                    )
                })
            }
        </table>
    )
}
