import axios from 'axios';
import React, { useState, useContext } from 'react'
import UserContext, { API_URL } from '../context/MyContent';
import { useNavigate } from 'react-router-dom';

export default function ChangepassScreen() {
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [pass3, setPass3] = useState('');
    const [error, setError] = useState('');
    const context = useContext(UserContext)
    const navigate = useNavigate();

    const change = () => {
        if (pass1 === '') {
            setError('Одоогийн нууц үгээ оруулна уу');
            return;
        }
        if (pass2 === '') {
            setError('Шинэ нууц үгээ оруулна уу');
            return;
        }
        if (pass3 === '') {
            setError('Шинэ нууц үгээ давтан оруулна уу');
            return;
        }
        if (pass2 !== pass3) {
            setError('Шинэ нууц үг тохирохгүй байна.');
            return;
        }
        if (pass2.length < 6) {
            setError('Нууц үг 5-аас дээш тэмдэгт оруулна уу');
            return;
        }

        const data = { pass1: pass1, pass2: pass2, pass3: pass3, token: context.token };
        context.setLoading(true);
        axios.post(API_URL + 'web/chgpass/', data)
            .then(res => {
                console.log(res.data)
                if (res.data.success) {
                    alert(res.data.message)
                    context.setLoading(false);
                    navigate('/dashboard')
                }
                else {
                    setError(res.data.message);
                    context.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                context.setLoading(false);
            })

    }

    return (
        <div class="dashcontainer">
            <h2>НУУЦ ҮГ СОЛИХ</h2>
            <table align="center">
                <tr>
                    <td colSpan="2" align="center" style={{ color: 'red' }}>{error}</td>
                </tr>
                <tr>
                    <td>Одоогийн нууц үг : </td>
                    <td>
                        <input type='password' name='pass1' placeholder='Одоогийн нууц үг' class='txtbox' value={pass1} onChange={(val) => { setPass1(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>Шинэ нууц үг : </td>
                    <td>
                        <input type='password' name='pass2' placeholder='Шинэ нууц үг' class='txtbox' value={pass2} onChange={(val) => { setPass2(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>Шинэ нууц үг /давт/ :</td>
                    <td>
                        <input type='password' name='pass3' placeholder='Нууц үгээ давт' class='txtbox' value={pass3} onChange={(val) => { setPass3(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <input type='button' value="Нууц үг солих" class='btnlogin' onClick={(e) => { e.preventDefault(); change(); }} />
                    </td>
                </tr>
            </table>
        </div>
    )
}
