import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/MyContent';

function MyHeader() {

    const [sval, setSval] = useState('');
    const navigate = useNavigate();
    const context = useContext(UserContext)

    const logout = () => {
        context.setToken('');
        context.setFname('');
        context.setUname('');
        context.setPhone('');
        context.setRegistr('');
        context.setInfo(null);
        navigate("/home");
    }

    return (
        <div className="headerbg">
            <div class="container">
                <table width="100%" align="center">
                    <tr>
                        <td align='left'>
                            <a href="/">
                                <img src="/logo.png" alt="Evolution Holding LLC" />
                            </a>
                        </td>
                        <td align='center' class="mysearch">
                            <i class="fas fa-search"></i>
                            <input type='search' name='s' value={sval} onChange={(val) => { setSval(val.target.value) }} placeholder='Search' class='searchbox' />
                        </td>
                        <td align='right' style={{ color: '#FFF' }}>
                            {
                                context.loading ?
                                    <img src="/loading1.gif" alt='' width={30} height={30} style={{ marginRight: 30 }} />
                                    : null
                            }
                            {context.token === undefined || context.token === '' ?
                                <>
                                    <input type='button' value="Нэвтрэх" class='btnlogin' onClick={(e) => { e.preventDefault(); navigate("/login"); }} />
                                </>
                                :
                                <>
                                    Сайн уу? <b style={{ marginLeft: 5, marginRight: 10 }}>{context.fname.substring(0, 1)}.{context.uname}</b>
                                    <input type='button' value="LOG OUT" class='btnlogin' onClick={(e) => { e.preventDefault(); logout(); }} />
                                </>
                            }
                        </td>
                    </tr>
                </table>
            </div>
        </div >
    )
}

export default MyHeader
