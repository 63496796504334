import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import UserContext from '../context/MyContent';

export default function MainMenu1() {
    const context = useContext(UserContext)

    return (
        <div class="mt-3">
            <table align="right" class="mt-5">
                <tr>
                    <td width="150" class="mymenucol">
                        <NavLink to="/dashboard/saving" className="d-flex mycolor align-items-center justify-content-center text-center full">Миний хөрөнгө оруулалт</NavLink>
                    </td>
                    <td width="150" class="mymenucol">
                        <NavLink to="/dashboard/lizing" className="d-flex mycolor align-items-center justify-content-center text-center full">Миний зээл</NavLink>
                    </td>
                    <td width="150" class="mymenucol">
                        <NavLink to="/dashboard/info" className="d-flex mycolor align-items-center justify-content-center text-center full">Миний мэдээлэл</NavLink>
                    </td>
                    <td width="150" class="mymenucol">
                        <NavLink to="/dashboard/changepass" className="d-flex mycolor align-items-center justify-content-center text-center full">Нууц үг солих</NavLink>
                    </td>
                    {
                        context.admins.filter(admins => { return (admins.includes(context.registr)) }).map((el, index) => (
                            <td width="150" class="mymenucol" key={index}>
                                <NavLink to="/dashboard/content" className="d-flex mycolor align-items-center justify-content-center text-center full">Веб контент</NavLink>
                            </td>
                        ))
                    }
                </tr>
            </table>
        </div>
    )
}
