import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Parser from 'html-react-parser';
import UserContext, { API_URL } from '../context/MyContent';

function ProductScreen() {
    const [proid, setProid] = useState(1);
    const [content, setContent] = useState('');
    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        axios.get(API_URL + 'web/product/' + proid.toString())
            .then(res => {
                setContent(res.data.newstext);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [proid])

    return (
        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: 15 }} >
            <tr>
                <td>
                    <div class={proid === 1 ? "ptabactive" : "ptab"} >
                        <NavLink to="/products" onClick={() => { setProid(1) }} className="plink">
                            <b>АВТОМАШИН БАРЬЦААЛСАН ЗЭЭЛ</b> ЗОГСООЛД БАЙРШУУЛАХ
                        </NavLink>
                    </div>
                    <div class={proid === 2 ? "ptabactive" : "ptab"}>
                        <NavLink to="/products" onClick={() => { setProid(2) }} className="plink">
                            <b>АВТОМАШИН БАРЬЦААЛСАН ЗЭЭЛ</b> УНААД ЯВАХ БОЛОМЖТОЙ
                        </NavLink>
                    </div>
                    <div class={proid === 3 ? "ptabactive" : "ptab"}>
                        <NavLink to="/products" onClick={() => { setProid(3) }} className="plink">
                            <b>ГАР УТАСНЫ ДУГААР БАРЬЦААЛСАН ЗЭЭЛ</b>
                        </NavLink>
                    </div>
                    <div class={proid === 4 ? "ptabactive" : "ptab"}>
                        <NavLink to="/products" onClick={() => { setProid(4) }} className="plink">
                            <b>АВТОМАШИНЫ ЛИЗИНГИЙН ХУДАЛДАА</b>
                        </NavLink>
                    </div>
                    <div class={proid === 5 ? "ptabactive" : "ptab"}>
                        <NavLink to="/products" onClick={() => { setProid(5) }} className="plink">
                            <b>ҮЛ ХӨДЛӨХ ХӨРӨНГИЙН ЗЭЭЛ</b>
                        </NavLink>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="productcontainer" style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30, paddingBottom: 30 }}>
                    {Parser(content)}
                </td>
            </tr>
        </table>
    )
}

export default ProductScreen
