import axios from 'axios';
import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import UserContext, { API_URL } from '../context/MyContent';


export default function LoginScreen() {
    const [userid, setUserid] = useState('');
    const [userpass, setUserpass] = useState('');
    const navigate = useNavigate();
    const context = useContext(UserContext)

    const login = async () => {

        if (userid === '') {
            alert('Нэвтрэх нэрээ оруулна уу')
            return
        }
        if (userpass === '') {
            alert('Нууц үгээ оруулна уу')
            return
        }
        context.setLoading(true);
        const res = await axios.post(API_URL + 'web/login/', {
            userid: userid,
            userpass: userpass
        });
        if (res.data.success) {
            context.setToken(res.data.token);
            context.setFname(res.data.info[0].fname);
            context.setUname(res.data.info[0].uname);
            context.setPhone(res.data.info[0].phone);
            context.setInfo(res.data.info);
            context.setRegistr(res.data.info[0].registr);
            context.setLoading(false);
            navigate("/dashboard");
        }
        else {
            context.setLoading(false);
            alert('Нэр нууц үг буруу байна.')
        }
    }


    return (
        <div class="logincontainer">
            <table width="100%">
                <tr>
                    <td>
                        <input type='text' name='username' placeholder='Нэвтрэх нэр' class='txtbox' value={userid} onChange={(val) => { setUserid(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type='password' name='userpass' placeholder='Нууц үг' class='txtbox' value={userpass} onChange={(val) => { setUserpass(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <a href='/resetpass' class="btnreset" onClick={(e) => { e.preventDefault(); navigate("/resetpass"); }}>НУУЦ ҮГ СЭРГЭЭХ</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type='button' value="Нэвтрэх" class='btnlogin1' onClick={(e) => { e.preventDefault(); login(); }} />
                    </td>
                </tr>
            </table>
        </div>
    )
}
