import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserStore } from "./context/MyContent";
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import HomeScreen from './screens/HomeScreen';
import Dashboard from './screens/Dashboard';

function App() {
  return (
    <UserStore>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/:page" element={<HomeScreen />} />
          <Route path="/:page/:id" element={<HomeScreen />} />
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/dashboard/:id" element={<Dashboard exact />} />
          <Route path="/dashboard/:id/:rid" element={<Dashboard exact />} />
          <Route
            path="*"
            element={<HomeScreen />}
          />
        </Routes>
      </BrowserRouter>
    </UserStore>
  );
}

export default App;
