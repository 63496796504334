import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import UserContext, { API_URL } from '../context/MyContent';

export default function MyLizingGraphic(props) {
    const [list, setList] = useState([]);
    const [haahdun, setHaahdun] = useState(0);
    var sumhuu = 0;
    var sumtuluh = 0;

    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/myzeelgraph/', {
            token: context.token,
            gereecode: context.hcode,
        })
            .then(res => {
                setList(res.data);
                axios.post(API_URL + 'web/lizinghaah/', {
                    token: context.token,
                    gereecode: context.hcode
                })
                    .then(res => {
                        setHaahdun(res.data.haahdun);
                        context.setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        context.setLoading(false);
                    })
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [])

    return (
        <div class="mysaving">
            <table width="100%">
                <tr>
                    <td>
                        <div class="mysavinghuulga">
                            <h2>Гэрээ : {context.hcode}</h2>
                            <h2>Эргэн төлөлтийн график</h2>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <td class="tblhead">№</td>
                                        <td class="tblhead">Огноо</td>
                                        <td class="tblhead hidephone">Зээл</td>
                                        <td class="tblhead hidephone">Хүү</td>
                                        <td class="tblhead">Төлөх</td>
                                        <td class="tblhead">Үлдэгдэл</td>
                                    </tr>
                                </thead>
                                {
                                    list.map(el => {
                                        sumhuu = sumhuu + el.huu;
                                        sumtuluh = sumtuluh + el.tuluh;
                                        return (
                                            <tr key={el.ognoo}>
                                                <td class="tblrow">{el.num}</td>
                                                <td class="tblrow">{el.ognoo}</td>
                                                <td class="tblrow hidephone">{el.zeel.toLocaleString()}</td>
                                                <td class="tblrow hidephone">{el.huu.toLocaleString()}</td>
                                                <td class="tblrow">{el.tuluh.toLocaleString()}</td>
                                                <td class="tblrow">{el.uldegdel.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="hidephone"></td>
                                    <td class="tbltitle hidephone">{sumhuu.toLocaleString()}</td>
                                    <td class="tbltitle">{sumtuluh.toLocaleString()}</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                    <td width="350" class="hidephone" style={{ textAlign: 'center', paddingTop: 60 }}>
                        <h2>ЗЭЭЛ ХААХ </h2>
                        <h3>{haahdun.toLocaleString()}</h3>
                        <p style={{ color: 'black', fontWeight: 'bold', marginTop: 50 }}>ЗЭЭЛИЙН ТӨЛӨЛТ ХИЙХ ЗААВАР:</p>
                    </td>
                </tr>
            </table>
        </div>
    )
}
