import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { API_URL, currencyFormat, currencyFormat2 } from '../context/MyContent';


export default function Calc1Screen() {
    const [list, setList] = useState([]);

    const [type, setType] = useState(1);
    const [sdate, setSdate] = useState(new Date());
    const [gereedun, setGereedun] = useState(1000000);
    const [hugatsaa, setHugatsaa] = useState(12);
    const [hhugatsaa, setHhugatsaa] = useState(0);
    const [huu, setHuu] = useState(3.0)


    const getcalc = () => {
        axios.post(API_URL + 'web/calc1/', {
            type: type,
            sdate: sdate,
            gereedun: gereedun,
            hhugatsaa: hhugatsaa,
            hugatsaa: hugatsaa,
            huu: huu
        })
            .then(res => {
                setList(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        axios.post(API_URL + 'web/calc1/', {
            type: type,
            sdate: sdate,
            gereedun: gereedun,
            hhugatsaa: hhugatsaa,
            hugatsaa: hugatsaa,
            huu: huu
        })
            .then(res => {
                setList(res.data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [])

    return (
        <div class="calcbg">
            <table width="100%">
                <tr>
                    <td align='center'>
                        <h2 class="activecalc"><NavLink className="mydefcolor" to="/calc/1">ЗЭЭЛИЙН ТООЦООЛУУР</NavLink></h2>
                    </td>
                    <td align='center'>
                        <h2><NavLink className="mydefcolor" to="/calc/2">ХӨРӨНГӨ ОРУУЛАЛТЫН ТООЦООЛУУР</NavLink></h2>
                    </td>
                </tr>
            </table>
            <table width="100%">
                <tr>
                    <td class="calcp mydefcolor">
                        ТӨРӨЛ
                    </td>
                    <td class="calcp">
                        <select class="custom-select form-control" value={type} onChange={(e) => { setType(e.target.value); }}>
                            <option value={0}>ТЭНЦҮҮ ТӨЛБӨРИЙН АРГАЧЛАЛ</option>
                            <option value={1}>ҮНДСЭН ЗЭЭЛ ТЭНЦҮҮ АРГАЧЛАЛ</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ЭХЛЭХ ОГНОО
                    </td>
                    <td class="calcp">
                        <DateTimePicker
                            disableClock={true}
                            clearIcon={null}
                            class="mydefcolor"
                            format='y-MM-dd'
                            value={sdate} onChange={date => { setSdate(date); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp mydefcolor">
                        ЗЭЭЛИЙН ХЭМЖЭЭ
                    </td>
                    <td class="calcp">
                        <input type="text" className="form-control mydefcolor" value={currencyFormat(gereedun)} placeholder="1000000" onChange={(e) => { setGereedun(parseInt(e.target.value.toString().replaceAll(',', ''))); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ЗЭЭЛИЙН ХУГАЦАА
                    </td>
                    <td class="calcp">
                        <select class="custom-select form-control" value={hugatsaa} onChange={(e) => { setHugatsaa(e.target.value); }}>
                            <option value={1}>1 Сар</option>
                            <option value={2}>2 Сар</option>
                            <option value={3}>3 Сар</option>
                            <option value={4}>4 Сар</option>
                            <option value={5}>5 Сар</option>
                            <option value={6}>6 Сар</option>
                            <option value={7}>7 Сар</option>
                            <option value={8}>8 Сар</option>
                            <option value={9}>9 Сар</option>
                            <option value={10}>10 Сар</option>
                            <option value={11}>11 Сар</option>
                            <option value={12}>12 Сар</option>
                            <option value={13}>13 Сар</option>
                            <option value={14}>14 Сар</option>
                            <option value={15}>15 Сар</option>
                            <option value={16}>16 Сар</option>
                            <option value={17}>17 Сар</option>
                            <option value={18}>18 Сар</option>
                            <option value={19}>19 Сар</option>
                            <option value={20}>20 Сар</option>
                            <option value={21}>21 Сар</option>
                            <option value={22}>22 Сар</option>
                            <option value={23}>23 Сар</option>
                            <option value={24}>24 Сар</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="calcp mydefcolor">
                        ЗЭЭЛИЙН ХҮҮ
                    </td>
                    <td class="calcp">
                        <input type="number" className="form-control" value={huu} placeholder="" onChange={(e) => { setHuu(e.target.value); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ХӨНГӨЛӨЛТТЭЙ ХУГАЦАА
                    </td>
                    <td class="calcp">
                        <input type="number" className="form-control" value={hhugatsaa} placeholder="" onChange={(e) => { setHhugatsaa(e.target.value); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">

                    </td>
                    <td class="calcp">
                        <button class="btncalc" onClick={() => { getcalc(); }}>Тооцох</button>
                    </td>
                </tr>
            </table>

            <table width="100%" border="0" class="calclist">
                <thead>
                    <tr>
                        <th class="txtcenter">ОГНОО</th>
                        <th class="txtcenter">ДУГААР</th>
                        <th class="txtcenter hidephone">ХҮҮ ТООЦСОН ХОНОГ</th>
                        <th class="txtcenter hidephone">ЗЭЭЛЙН ХҮҮ</th>
                        <th class="txtcenter hidephone">ҮНДСЭН ТӨЛБӨР</th>
                        <th class="txtcenter">НИЙТ ТӨЛБӨР</th>
                        <th class="txtcenter">ЗЭЭЛИЙН ҮЛДЭГДЭЛ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td align='center'>
                                        {item.ognoo}
                                    </td>
                                    <td align='center'>
                                        {item.num}
                                    </td>
                                    <td align='center' class="hidephone">
                                        {item.honog}
                                    </td>
                                    <td align='center' class="hidephone">
                                        {currencyFormat2(item.bodsonhuu)} ₮
                                    </td>
                                    <td align='center' class="hidephone">
                                        {currencyFormat2(item.zeel)} ₮
                                    </td>
                                    <td align='center'>
                                        {currencyFormat2(item.niit)} ₮
                                    </td>
                                    <td align='center'>
                                        {currencyFormat2(item.uldegdel)} ₮
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}
