import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { API_URL, currencyFormat, currencyFormat2 } from '../context/MyContent';


export default function Calc2Screen() {
    const [list, setList] = useState([]);
    const [type, setType] = useState(0);
    const [sdate, setSdate] = useState(new Date(new Date().setDate(new Date().getDate() + 0)));
    const [orlogo1, setOrlogo1] = useState(10000000);
    const [hugatsaa, setHugatsaa] = useState(12);
    const [orlogo, setOrlogo] = useState(100000);
    const [huu, setHuu] = useState(1.7 * 12)
    const [sumho, setSumho] = useState(11200000);
    var sum1 = 0;
    var sum2 = 0;
    var sum3 = 0;

    const getcalc = () => {
        axios.post(API_URL + 'web/calc2/', {
            type: type,
            sdate: sdate,
            orlogo1: orlogo1,
            orlogo: orlogo,
            hugatsaa: hugatsaa,
            huu: huu
        })
            .then(res => {
                setList(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {

        setSumho(parseInt(orlogo * hugatsaa) + parseInt(orlogo1));
        if (type == 1) {
            setOrlogo(0);
        }
        else {
            setOrlogo(100000);
        }
        axios.post(API_URL + 'web/calc2/', {
            type: type,
            sdate: sdate,
            orlogo1: orlogo1,
            orlogo: orlogo,
            hugatsaa: hugatsaa,
            huu: huu
        })
            .then(res => {
                setList(res.data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [])

    return (
        <div class="calcbg">
            <table width="100%">
                <tr>
                    <td align='center'>
                        <h2><NavLink className="mydefcolor" to="/calc/1">ЗЭЭЛИЙН ТООЦООЛУУР</NavLink></h2>
                    </td>
                    <td align='center'>
                        <h2 class="activecalc"><NavLink className="mydefcolor" to="/calc/2">ХӨРӨНГӨ ОРУУЛАЛТЫН ТООЦООЛУУР</NavLink></h2>
                    </td>
                </tr>
            </table>
            <table width="100%">
                <tr>
                    <td class="calcp mydefcolor">
                        ТӨРӨЛ
                    </td>
                    <td class="calcp">
                        <select class="custom-select form-control" value={type} onChange={(e) => { setType(e.target.value); }}>
                            <option value={0}>САР БҮР ТОГТМОЛ НЭМЭХ</option>
                            <option value={1}>НЭГ УДАА ХӨРӨНГӨ ОРУУЛАХ</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ЭХЛЭХ ОГНОО
                    </td>
                    <td class="calcp">
                        <DateTimePicker
                            disableClock={true}
                            clearIcon={null}
                            class="mydefcolor"
                            format='y-MM-dd'
                            value={sdate} onChange={date => { setSdate(date); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp mydefcolor">
                        ЭХНИЙ ОРЛОГО
                    </td>
                    <td class="calcp">
                        <input type="text" className="form-control" value={currencyFormat(orlogo1)} placeholder="0" onChange={(e) => { setOrlogo1(parseInt(e.target.value.toString().replaceAll(',', ''))); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ХУГАЦАА
                    </td>
                    <td class="calcp">
                        <select class="custom-select form-control" value={hugatsaa} onChange={(e) => { setHugatsaa(e.target.value); }}>
                            <option value={1}>1 Сар</option>
                            <option value={2}>2 Сар</option>
                            <option value={3}>3 Сар</option>
                            <option value={4}>4 Сар</option>
                            <option value={5}>5 Сар</option>
                            <option value={6}>6 Сар</option>
                            <option value={7}>7 Сар</option>
                            <option value={8}>8 Сар</option>
                            <option value={9}>9 Сар</option>
                            <option value={10}>10 Сар</option>
                            <option value={11}>11 Сар</option>
                            <option value={12}>12 Сар</option>
                            <option value={13}>13 Сар</option>
                            <option value={14}>14 Сар</option>
                            <option value={15}>15 Сар</option>
                            <option value={16}>16 Сар</option>
                            <option value={17}>17 Сар</option>
                            <option value={18}>18 Сар</option>
                            <option value={19}>19 Сар</option>
                            <option value={20}>20 Сар</option>
                            <option value={21}>21 Сар</option>
                            <option value={22}>22 Сар</option>
                            <option value={23}>23 Сар</option>
                            <option value={24}>24 Сар</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="calcp mydefcolor">
                        САР БҮР ХИЙХ ОРЛОГО
                    </td>
                    <td class="calcp">
                        <input type="text" className="form-control" value={currencyFormat(orlogo)} placeholder="" onChange={(e) => { setOrlogo(parseInt(e.target.value.toString().replaceAll(',', ''))); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">
                        ЖИЛИЙН ХҮҮ
                    </td>
                    <td class="calcp">
                        <input type="number" className="form-control" value={huu} placeholder="" onChange={(e) => { setHuu(e.target.value); }} />
                    </td>
                </tr>
                <tr>
                    <td class="calcp">

                    </td>
                    <td class="calcp">
                        <button class="btncalc" onClick={() => { getcalc(); }}>Тооцох</button>
                    </td>
                </tr>
            </table>

            <table width="100%" border="0" class="calclist">
                <thead>
                    <tr>
                        <th class="txtcenter">ОГНОО</th>
                        <th class="txtcenter hidephone">САР</th>
                        <th class="txtcenter hidephone">ХҮҮ ТООЦСОН ХОНОГ</th>
                        <th class="txtcenter">БОДОГДОХ ХҮҮ-₮</th>
                        <th class="txtcenter hidephone">НЭМЭГДСЭН ХӨРӨНГӨ ОРУУЛАЛТ</th>
                        <th class="txtcenter">НИЙТ МӨНГӨ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => {
                            sum1 = sum1 + item.bodsonhuu;
                            sum2 = sum2 + item.nemsen;
                            sum3 = sum3 + item.niit
                            return (
                                <tr key={item.id}>
                                    <td align='center'>
                                        {item.ognoo}
                                    </td>
                                    <td align='center' class="hidephone">
                                        {item.num} сар
                                    </td>
                                    <td align='center' class="hidephone">
                                        {item.honog}
                                    </td>
                                    <td align='center'>
                                        {currencyFormat2(item.bodsonhuu)} ₮
                                    </td>
                                    <td align='center' class="hidephone">
                                        {currencyFormat2(item.nemsen)} ₮
                                    </td>
                                    <td align='center'>
                                        {currencyFormat2(item.niit)} ₮
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="hidephone"></td>
                        <td class="hidephone"></td>
                        <td align='center'><b>{currencyFormat2(sum1)} ₮</b></td>
                        <td align='center' class="hidephone"><b>{currencyFormat2(sum2)} ₮</b></td>
                        <td align='center'><b>{currencyFormat2(sum1 + sum2 + orlogo1)} ₮</b></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
