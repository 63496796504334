import React from 'react'
import { useParams } from 'react-router-dom';
import Calc1Screen from './Calc1Screen';
import Calc2Screen from './Calc2Screen';

export default function CalcScreen() {
    let params = useParams();
    return (
        <div class="calcbg">
            {
                (() => {
                    switch (params.id) {
                        case '1': return <Calc1Screen />
                        case '2': return <Calc2Screen />
                        default: return <Calc2Screen />
                    }
                })()
            }
        </div>
    )
}
