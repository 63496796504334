import React from 'react'
import { MaterialCommunityIcons, Feather, EvilIcons } from 'react-web-vector-icons';
require('react-web-vector-icons/fonts');

function ContactScreen() {
    return (
        <div class="boxcontainer">
            <table class="contacttxt">
                <tr>
                    <td>
                        <Feather
                            name='globe'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>+(976) 7777 4353</td>
                </tr>
                <tr>
                    <td>
                        <Feather
                            name='facebook'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>
                        <a href="https://www.facebook.com/Evoholding.mn/" target="_blank">
                            Evolution Holding
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <MaterialCommunityIcons
                            name='youtube'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>Evolution Holding LLC</td>
                </tr>
                <tr>
                    <td>
                        <MaterialCommunityIcons
                            name='email-outline'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>evolution@evo.mn</td>
                </tr>
                <tr>
                    <td>
                        <Feather
                            name='phone'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>+(976) 7777 4353</td>
                </tr>
                <tr>
                    <td>
                        <EvilIcons
                            name='location'
                            color='#27665a'
                            size={18}
                        />
                    </td>
                    <td>Байгууллагын хаяг:
                        Улаанбаатар хот, Баянгол дүүрэг, 7-р хороо,
                        3 хороолол, Ард-Аюушийн өргөн чөлөө,
                        76а байр, 4 давхарт</td>
                </tr>
            </table>
        </div>
    )
}

export default ContactScreen
