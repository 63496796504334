import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import UserContext, { API_URL } from '../context/MyContent';


function AboutScreen() {
    const [cid, setCid] = useState(6)
    const [content, setContent] = useState('');
    const context = useContext(UserContext)

    let params = useParams();

    useEffect(() => {
        if (params.id) {
            setCid(params.id)
        }
        context.setLoading(true);
        axios.get(API_URL + 'web/content/' + cid.toString())
            .then(res => {
                setContent(res.data.newstext);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [cid, params.id])

    return (
        <div class="aboutcontainer">
            <div class="cxm30">
                {Parser(content)}
            </div>
        </div>
    )
}

export default AboutScreen
