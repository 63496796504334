import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import UserContext, { API_URL } from '../context/MyContent';

export default function MySavingScreen() {
    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [slist, setSlist] = useState([]);
    const [gereecode, setGereecode] = useState('');
    const context = useContext(UserContext)
    const navigate = useNavigate();

    const showinfo = (gereecode) => {
        setGereecode(gereecode);
        setList1(slist.filter(slist => { return (slist.gereecode.includes(gereecode)) }));
    }

    const showhuulga = (type) => {
        if (gereecode === '') {
            alert('Хуулга харах гэрээгээ сонгоно уу');
            return
        }
        context.setHtype(type);
        context.setHcode(gereecode);
        navigate("/dashboard/savinghuulga");
    }

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/mysaving/', {
            token: context.token
        })
            .then(res => {
                setList(res.data);
                setList1(res.data);
                setSlist(res.data);
                if (res.data.length > 0) {
                    setGereecode(res.data[0].gereecode);
                }
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [])

    return (
        <div class="mysaving">
            <table>
                <tr>
                    {
                        list.map(el => (
                            <td key={el.gereecode}>
                                <div class={gereecode === el.gereecode ? "stabactive" : "stab"}>
                                    <NavLink to="/dashboard/saving" style={gereecode === el.gereecode ? { color: 'white' } : { color: '#234f41' }} onClick={(e) => { e.preventDefault(); showinfo(el.gereecode) }} className="slink">
                                        {el.gereecode}
                                    </NavLink>
                                </div>
                            </td>
                        ))
                    }
                </tr>
            </table>
            <table width="100%">
                <tr>
                    <td>
                        <div class="mysavinginfo">
                            <table width="100%">
                                <tr>
                                    <td>ГЭРЭЭНИЙ ТӨРӨЛ</td>
                                    <td>{list1[0] && list1[0].pname}</td>
                                </tr>
                                <tr>
                                    <td>ГЭРЭЭНИЙ КОД</td>
                                    <td>{list1[0] && list1[0].gereecode}</td>
                                </tr>
                                <tr>
                                    <td>ЭХНИЙ ҮЛДЭГДЭЛ</td>
                                    <td>{list1[0] && list1[0].gereedun.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>НЭМЭГДСЭН ДҮН</td>
                                    <td>{list1[0] && list1[0].nemsen.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ХАСАГДСАН ДҮН</td>
                                    <td>{list1[0] && list1[0].hassan.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ЭЦСИЙН ҮЛДЭГДЭЛ</td>
                                    <td>{list1[0] && list1[0].gereeuld.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ХҮҮ / Сарын /</td>
                                    <td>{list1[0] && list1[0].huu}</td>
                                </tr>
                                <tr>
                                    <td>ХУРИМТЛАГДСАН ХҮҮ</td>
                                    <td>{list1[0] && list1[0].bodsonhuu.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>СУУТГАГДСАН ТАТВАР / 10% /</td>
                                    <td>{list1[0] && list1[0].tatvardun.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>ГАРТ ОЛГОХ</td>
                                    <td>{list1[0] && (list1[0].bodsonhuu).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>УРГИЙН ОВОГ</td>
                                    <td>{list1[0] && list1[0].ovog}</td>
                                </tr>
                                <tr>
                                    <td>ЭЦГИЙН НЭР</td>
                                    <td>{list1[0] && list1[0].fname}</td>
                                </tr>
                                <tr>
                                    <td>НЭР</td>
                                    <td>{list1[0] && list1[0].uname}</td>
                                </tr>
                                <tr>
                                    <td>РЕГИСТРИЙН ДУГААР</td>
                                    <td>{list1[0] && list1[0].registr}</td>
                                </tr>
                                <tr>
                                    <td>БАНКНЫ НЭР</td>
                                    <td>{list1[0] && list1[0].bankname}</td>
                                </tr>
                                <tr>
                                    <td>ДАНСНЫ ДУГААР</td>
                                    <td>{list1[0] && list1[0].dansname}</td>
                                </tr>
                                <tr>
                                    <td>ИРГЭНИЙ ҮНЭМЛЭХНИЙ ХАЯГ</td>
                                    <td>{list1[0] && list1[0].addr}</td>
                                </tr>
                                <tr>
                                    <td>УТАСНЫ ДУГААР</td>
                                    <td>{list1[0] && list1[0].phone}</td>
                                </tr>
                            </table>
                        </div>
                        <table width="100%" class="hidephone" style={{ marginTop: 20 }}>
                            <tr>
                                <td>
                                    <input type="button" class="savingbtn" value="Хөрөнгө оруулалтын хуулга" onClick={() => { showhuulga('zeel'); }} />
                                </td>
                                <td align='right'>
                                    <input type="button" class="savingbtn" value="Хүүний хуулга" onClick={() => { showhuulga('huu'); }} />
                                </td>
                            </tr>
                        </table>
                        <table width="100%" class="showphone" align="center" style={{ marginTop: 20 }}>
                            <tr>
                                <td align='center'>
                                    <input type="button" class="savingbtn" value="Хөрөнгө оруулалтын хуулга" onClick={() => { showhuulga('zeel'); }} />
                                </td>
                            </tr>
                            <tr>
                                <td align='center'>
                                    <input type="button" class="savingbtn" value="Хүүний хуулга" onClick={() => { showhuulga('huu'); }} />
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="hidephone" style={{ textAlign: 'center', paddingTop: 60 }}>
                        <img src='/cert.jpg' width="70%" alt='' />
                        <p style={{ color: 'black', fontWeight: 'bold', marginTop: 50 }}>САНАМЖ:</p>
                        <p align="center" style={{ color: 'black', textAlign: 'center', marginLeft: 30, marginRight: 30 }}>Та өөрийн хувийн мэдээллээ шалгана уу. Хэрэв таны мэдээлэл буруу эсвэл алдаатай байвал 77774353 дугаарын утсаар манай ажилтантай холбогдож мэдээлэлдээ засвар хийлгүүлнэ үү.</p>
                    </td>
                </tr>
                <tr>
                    <td class="showphone" align='center'>
                        <img src='/cert.jpg' width="70%" alt='' />
                        <p style={{ color: 'black', fontWeight: 'bold', marginTop: 50 }}>САНАМЖ:</p>
                        <p align="center" style={{ color: 'black', textAlign: 'center', marginLeft: 30, marginRight: 30 }}>Та өөрийн хувийн мэдээллээ шалгана уу. Хэрэв таны мэдээлэл буруу эсвэл алдаатай байвал 77774353 дугаарын утсаар манай ажилтантай холбогдож мэдээлэлдээ засвар хийлгүүлнэ үү.</p>
                    </td>
                </tr>
            </table>

        </div>
    )
}
