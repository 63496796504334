import React, { useContext } from 'react'
import UserContext from '../context/MyContent';


export default function MyInfoScreen() {
    const context = useContext(UserContext)

    return (
        <div class="dashcontainer">
            <h2>МИНИЙ МЭДЭЭЛЭЛ</h2>
            <table>
                <tr>
                    <td>Регистер : </td>
                    <td>
                        <b>{context.registr}</b>
                    </td>
                </tr>
                <tr>
                    <td>Эцгийн нэр : </td>
                    <td>
                        <b>{context.fname}</b>
                    </td>
                </tr>
                <tr>
                    <td>Нэр : </td>
                    <td>
                        <b> {context.uname}</b>
                    </td>
                </tr>
                <tr>
                    <td>Утас : </td>
                    <td>
                        <b>  {context.phone}</b>
                    </td>
                </tr>
                <tr>
                    <td>Данс : </td>
                    <td>
                        <b> {context.info[0].dansname} / {context.info[0].bankname} /</b>
                    </td>
                </tr>
                <tr>
                    <td>Хаяг : </td>
                    <td>
                        <b> {context.info[0].addr}</b>
                    </td>
                </tr>
            </table>
        </div>
    )
}
