import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import UserContext, { API_URL } from '../context/MyContent';
import DateTimePicker from 'react-datetime-picker';

export default function MyLizingHuulga(props) {
    const [list, setList] = useState([]);
    const [sdate, setSdate] = useState(new Date(new Date().setDate(new Date().getDate() - 14)));
    const [edate, setEdate] = useState(new Date(new Date().setDate(new Date().getDate() + 0)));
    const [haahdun, setHaahdun] = useState(0);
    var orlogo = 0;
    var zarlaga = 0;

    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/myzeelhuulga/', {
            token: context.token,
            gereecode: context.hcode,
            sdate: sdate,
            edate: edate
        })
            .then(res => {
                setList(res.data);
                axios.post(API_URL + 'web/lizinghaah/', {
                    token: context.token,
                    gereecode: context.hcode
                })
                    .then(res => {
                        setHaahdun(res.data.haahdun);
                        context.setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        context.setLoading(false);
                    })
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [sdate, edate])

    return (
        <div class="mysaving">
            <table width="100%">
                <tr>
                    <td>
                        <div class="mysavinghuulga">
                            <h2>Гэрээ : {context.hcode}</h2>
                            <h2>Эргэн төлөлтийн хуулга</h2>
                            <table width="100%">
                                <tr>
                                    <td width="100" style={{ padding: 5 }}>Хугацааны интервал : </td>
                                    <td width={120} style={{ padding: 5 }}>
                                        <DateTimePicker
                                            disableClock={true}
                                            clearIcon={null}
                                            format='y-MM-dd'
                                            value={sdate} onChange={date => { setSdate(date); }} />

                                    </td>
                                    <td width={120} style={{ padding: 5 }}>
                                        <DateTimePicker
                                            disableClock={true}
                                            clearIcon={null}
                                            format='y-MM-dd'
                                            value={edate} onChange={date => { setEdate(date); }} />
                                    </td>
                                    <td></td>
                                </tr>
                            </table>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <td class="tblhead">Огноо</td>
                                        <td class="tblhead">Зарлага</td>
                                        <td class="tblhead">Орлого</td>
                                        <td class="tblhead">Үлдэгдэл</td>
                                        <td class="tblhead">Гүйлгээний утга</td>
                                    </tr>
                                </thead>
                                {
                                    list.map((el, index) => {
                                        zarlaga = zarlaga + el.zarlaga;
                                        orlogo = orlogo + el.orlogo;
                                        return (
                                            <tr key={index}>
                                                <td class="tblrow">{el.ognoo}</td>
                                                <td class="tblrow">{el.zarlaga.toLocaleString()}</td>
                                                <td class="tblrow">{el.orlogo.toLocaleString()}</td>
                                                <td class="tblrow">{el.uld.toLocaleString()}</td>
                                                <td class="tblrow">{el.descr}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td></td>
                                    <td class="tbltitle">{zarlaga.toLocaleString()}</td>
                                    <td class="tbltitle">{orlogo.toLocaleString()}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                    <td width="350" class="hidephone" style={{ textAlign: 'center', paddingTop: 60 }}>
                        <h2>ЗЭЭЛ ХААХ </h2>
                        <h3>{haahdun.toLocaleString()}</h3>
                        <p style={{ color: 'black', fontWeight: 'bold', marginTop: 50 }}>ЗЭЭЛИЙН ТӨЛӨЛТ ХИЙХ ЗААВАР:</p>
                    </td>
                </tr>
            </table>
        </div>
    )
}
