import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserContext, { API_URL, currencyFormat } from '../context/MyContent';

export default function ShopinfoScreen() {
    const [carinfo, setCarinfo] = useState([])
    const context = useContext(UserContext)
    let params = useParams();

    const getdata = async () => {
        context.setLoading(true);
        const r7 = await axios.get(API_URL + 'web/carbyid/' + params.id,);
        setCarinfo(r7.data);
        context.setLoading(false);
    }

    useEffect(() => {
        getdata()
    }, [params.id])

    return (
        <div class="shopcontainer">
            {carinfo.map(el => (
                <div>
                    <h4 style={{ marginLeft: 30 }}>{el.carname} {el.markname}</h4>
                    <p style={{ marginLeft: 50 }}>
                        <b>Он : {el.pyear} / {el.iyear}</b><br />
                        <b>Ангилал :</b> <b>{el.cartype}</b><br />
                        <b>Мотор : {el.motorname}</b><br />
                        <b>Хүрд : {el.hurd}</b><br />
                        <b>Өнгө : {el.colorname}</b><br />
                        <b>Үнэ : {currencyFormat(el.une)}₮</b><br />
                        <b>Утас : {el.phone}</b><br />
                        <b>{el.nemelt}</b><br />
                    </p>
                    <img src={el.img1} width="50%" style={{ padding: 5 }} />
                    <img src={el.img2} width="50%" style={{ padding: 5 }} />
                    <img src={el.img3} width="50%" style={{ padding: 5 }} />
                    <img src={el.img4} width="50%" style={{ padding: 5 }} />
                    <img src={el.img5} width="50%" style={{ padding: 5 }} />
                    <img src={el.img6} width="50%" style={{ padding: 5 }} />
                    <img src={el.img7} width="50%" style={{ padding: 5 }} />
                    <img src={el.img8} width="50%" style={{ padding: 5 }} />
                </div>
            ))}
        </div>
    )
}
