import React from 'react'
import { useParams } from 'react-router-dom';
import MainMenu from '../components/MainMenu'
import MyHeader from '../components/MyHeader'
import RightIcons from '../components/RightIcons';
import AboutScreen from './AboutScreen';
import CalcScreen from './CalcScreen';
import ContactScreen from './ContactScreen';
import LoginScreen from './LoginScreen';
import ProductScreen from './ProductScreen';
import ResetScreen from './ResetScreen';
import SavingScreen from './SavingScreen';
import ShopinfoScreen from './ShopinfoScreen';
import ShopScreen from './ShopScreen';

function HomeScreen() {
    let params = useParams();

    return (
        <div class="bg3">
            <MyHeader />
            <div class="container">
                <div class="row">
                    <MainMenu />
                </div>
                <div class="row">
                    <table width="100%">
                        <tr>
                            <td align='left' class="hidephone" width="100">
                                <img src="/logomn.png" style={{ marginTop: 100 }} alt="" />
                            </td>
                            {
                                (() => {
                                    switch (params.page) {
                                        case 'about': return null;
                                        case 'products': return null;
                                        case 'saving': return null;
                                        case 'calc': return null;
                                        case 'login': return null;
                                        case 'resetpass': return null;
                                        case 'shop': return null;
                                        case 'shopinfo': return null;
                                        default: return (
                                            <td align='left' class="myvideowidth">
                                                <div class="bg-white d-flex align-items-center justify-content-center mt-5 p-1 myvideowidth">
                                                    <video controls loop width="100%" autoPlay muted>
                                                        <source src="/intro.mp4" type="video/mp4" />
                                                        This browser does not display the video tag.
                                                    </video>
                                                </div>
                                                <div class="carinfobox myvideowidth">
                                                    <img src="http://www.cscc.mn/evo/banner/banner.jpg" width="100%" alt="" />
                                                </div>
                                            </td>
                                        )
                                    }
                                })()
                            }

                            <td align='left' valign='top'>
                                {
                                    (() => {
                                        switch (params.page) {
                                            case 'about': return <AboutScreen />
                                            case 'products': return <ProductScreen />
                                            case 'contact': return <ContactScreen />
                                            case 'saving': return <SavingScreen />
                                            case 'calc': return <CalcScreen />
                                            case 'login': return <LoginScreen />
                                            case 'resetpass': return <ResetScreen />
                                            case 'shop': return <ShopScreen />
                                            case 'shopinfo': return <ShopinfoScreen />
                                            default: {
                                                switch (params.id) {
                                                    case 'contact': return <ContactScreen />
                                                    default: return null
                                                }
                                            }
                                        }
                                    })()
                                }
                            </td>
                            <td align='right' width="100" class="hidephone">
                                <RightIcons />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default HomeScreen
