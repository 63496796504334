import React, { useState, useEffect, useContext } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams } from 'react-router-dom';
import UserContext, { API_URL } from '../context/MyContent';
import axios from 'axios';


export default function WebContentEditScreen() {
    let params = useParams();
    const [id, setId] = useState(0)
    const [newstitle, setNewstitle] = useState('')
    const [newstext, setNewstext] = useState('')
    const context = useContext(UserContext)

    const savecontent = () => {
        context.setLoading(true);
        axios.post(API_URL + 'web/savecontent/', {
            token: context.token,
            id: id,
            newstitle: newstitle,
            newstext: newstext,
            registr: context.registr
        })
            .then(res => {
                alert(res.data.msg)
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            });
    }

    useEffect(() => {
        context.setLoading(true);
        axios.post(API_URL + 'web/getcontent/', {
            token: context.token,
            id: params.rid,
        })
            .then(res => {
                setNewstext(res.data.newstext);
                setNewstitle(res.data.newstitle);
                setId(params.rid);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [params.rid])

    return (
        <table width="100%">
            <tr>
                <td style={{ padding: 5 }}>
                    <input type="text" className="form-control mydefcolor" value={newstitle} placeholder="" onChange={(e) => { setNewstitle(e.target.value); }} />
                </td>
            </tr>
            <tr>
                <td style={{ padding: 5 }}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={newstext}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setNewstext(data);
                        }}
                    />
                </td>
            </tr>
            <tr>
                <td style={{ padding: 5 }}>
                    <input type="button" className="form-control mydefcolor" value="Хадгалах" onClick={() => { savecontent(); }} />
                </td>
            </tr>
        </table>
    )
}
