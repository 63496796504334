import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../context/MyContent';


export default function ResetScreen() {
    const [registr, setRegistr] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();
    const respass = async () => {
        if (registr === '') {
            alert('Нэвтрэх нэрээ оруулна уу')
            return
        }
        if (phone === '') {
            alert('Утасны дугаараа оруулна уу')
            return
        }
        const res = await axios.post(API_URL + 'web/resetpass/', {
            registr: registr,
            phone: phone
        });
        if (res.data.success) {
            navigate("/login");
        }
        else {
            alert(res.data.msg)
        }
    }


    return (
        <div class="logincontainer">
            <table width="100%">
                <tr>
                    <td>
                        <input type='text' name='registr' placeholder='Регистрийн дугаар' class='txtbox' value={registr} onChange={(val) => { setRegistr(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type='text' name='phone' placeholder='Утасны дугаар' class='txtbox' value={phone} onChange={(val) => { setPhone(val.target.value) }} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type='button' value="Нууц үг сэргээх" class='btnlogin1' onClick={(e) => { e.preventDefault(); respass(); }} />
                    </td>
                </tr>
            </table>
        </div>
    )
}
