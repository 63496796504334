import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import Parser from 'html-react-parser';
import UserContext, { API_URL } from '../context/MyContent';

function SavingScreen() {
    const [proid, setProid] = useState(11);
    const [content, setContent] = useState('');
    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        axios.get(API_URL + 'web/product/' + proid.toString())
            .then(res => {
                setContent(res.data.newstext);
                context.setLoading(false);
            })
            .catch(err => {
                console.log(err);
                context.setLoading(false);
            })
    }, [proid])

    return (
        <table width="100%" cellPadding="0" cellSpacing="0" style={{ marginTop: 15 }} >
            <tr>
                <td class="productcontainer" style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 20, paddingBottom: 10 }}>
                    <div class="savingscroll">
                        {Parser(content)}
                    </div>
                </td>
            </tr>
        </table>
    )
}

export default SavingScreen
